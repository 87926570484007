import styled, { keyframes } from 'styled-components';
import media from 'styled-media-query'
import { Link } from '@styled-icons/boxicons-regular'
import { Container, EachInfoContainer } from '../shared/container'
import { Title } from '../shared/text-styled'
import PinkLine from '../shared/PinkLine'
import { alvTokens } from '@dasa-health/alma-tokens'
import { Button } from '@dasa-health/alma-react'
import { CircleButton } from '../shared/CircleButton/styled';

export const LinkIcon = styled(Link)`
  &:hover {
    color: var(--pink);
  }
`

export const SingleExamContainer = styled(Container)`
  max-width: 1092px;
  padding: 0 0 19.3rem;
  position: relative;
  ${media.lessThan('medium')`
    width: 100%;
    padding: 0 0 2.4rem;
  `}
`

export const SingleExamContent = styled.div`
  display: flex;  
  align-items: flex-start;
  gap: 2.4rem;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

export const ExamName = styled(Title)`
  font-size: 40px;
  font-weight: 700;
  color: ${props => props.theme.colors.brand.primary[10]};
  letter-spacing: -1px;
  line-height: 120%;
  max-width: none;
  margin-bottom: 1.6rem;
  
  ${media.lessThan('medium')`
    font-size: 20px;
    letter-spacing: inherit;
  `}
`

export const ExamSummaryTitle = styled.h2`
  font-size: 16px;
  color: ${props => props.theme.colors.palette.black};
  font-weight: 500;
  line-height: 150%;
`

export const ExamSpecTitle = styled.h2`
  font-size: 12px;
  color: ${props => props.theme.colors.palette.black};
  font-weight: 700;
  line-height: 133%;
  letter-spacing: 1.5px;
  margin-bottom: .8rem;
  text-transform: uppercase;

  &:first-child {
    margin-top: 0;
  }
`

export const AllGenesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

export const ModalAllGenesContainer = styled(AllGenesContainer)`
  margin-top: 20px;
`

export const EachGenesContainer = styled.span`
  border-radius: 24px;
  background: rgba(22, 22, 22, 0.12);
  background-size: cover;
  margin: 0.5rem 1.5rem 0.5rem 0;
  
  ${media.lessThan('medium')`
    margin: 0 1.2rem 1.6rem 0;
  `}
`

export const ModalEachGenesContainer = styled(EachGenesContainer)`
  ${media.lessThan('medium')`
    margin: 0 .8rem 1.6rem 0;
  `}
`

export const EachGenesName = styled.p`
  font-size: 12px;
  color: ${alvTokens.ColorPaletteGray30};
  text-align: center;
  line-height: 133%;
  padding: .4rem .8rem;
  margin: 0;
`

export const ViewMore = styled.a`
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: var(--pink);
  text-decoration: underline;
  cursor: pointer;
  margin-top: 1.5rem;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`

export const CopyPasteButton = styled.p`
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  float: right;
  position: ${props => (props.tuss ? 'none' : 'relative')};
`

export const CopyIcon = styled.i`
  display: flex;
  font-size: 1.6rem;
  margin-right: .6rem;
  color: var(--white);
`

export const ViewAllIcon = styled(CopyIcon)``

export const Copy = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 115%;
  margin: 0;
  color: ${alvTokens.ColorPaletteWhite};
`

export const ViewAll = styled(Copy)``

export const Icon = styled.i`
  font-size: 20px;
  height: 2rem;
  margin-right: 1rem;
`

export const ContentRow = styled.span`
  display: flex;
  & > * {
    &:first-child {
      white-space: nowrap;
      font-weight: 700;
    }
    &:last-child {
      margin-left: .5rem;
      text-transform: none;
    }
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    & > * {
      &:first-child {
        margin-bottom: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
  `}
`

export const Top = styled.a`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  color: #7D7D7D;
  margin: 6rem 0;

  &:hover {
    color: var(--pink);
  }

  ${media.lessThan('medium')`
    margin: 2.15em 0;
  `}
`

export const TopIcon = styled.i`
  font-size: 20px;
  position: relative;
  top: 8px;
  padding-left: 0.5rem;
`

export const InfoList = styled.ul`
  font-size: 14px;
  color: ${props => props.theme.colors.typeface.smooth};
  font-weight: 400;
  line-height: 170%;
  margin: .8rem 0 0;
  padding: 0 0 0 1rem;
  list-style: none;
`

export const SampleType = styled.div``

export const InfoListItem = styled.li`
  display: flex;
  line-height: 170%;
  color: ${props => props.theme.colors.palette.black};

  &:before {
    content: "●";
    font-size: 6px;
    margin-right: .8rem;
  }

  ${media.lessThan('medium')`
    color: ${props => props.theme.colors.palette.black};
  `}
`

export const InfoListBulleted = styled(InfoList)`
  list-style: disc;
  padding-left: 2.5rem;
  margin: 0;
`

export const ColumnExam = styled.div`
  background: ${alvTokens.ColorNeutral20};
  padding: .8rem;
  border-radius: 4px;
  width: 40.3rem;

  ${media.lessThan('medium')`
    width: calc(100vw - 3.2rem);
    padding: 1.6rem;
  `}
`

export const ColumnExamContent = styled.div`
  background: var(--white);
  padding: 1.8rem 2.2rem;
  border-radius: 4px;
`

export const InfoContainer = styled(EachInfoContainer)`
  padding-bottom: 2.4rem;
  margin-bottom: 2.4rem;
  border-bottom: 1px solid rgba(22, 22, 22, 0.24);
`

export const ButtonsDiv = styled.div`
  display: flex;
  gap: 8px;
`

export const OtherNames = styled.p`
  font-size: 14px;
  color: ${alvTokens.ColorPaletteGray30};
  line-height: 170%;
  margin: 0;
`

export const Synonymies = styled(OtherNames)``

export const ChipAnsDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
  
  ${media.lessThan('medium')`
    margin-bottom: 1.6rem;
  `}
`

export const ColumnDetails = styled.div`
  width: 64.7rem;
  z-index: 2;

  ${media.lessThan('medium')`
    width: calc(100vw - 3.2rem);
    padding: 0 1.6rem;
  `}
`

export const SingleContainer = styled.div`
  position: relative;
`

export const ImgGeneticContainer = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 361px;
  height: 723px;

  .gatsby-image-wrapper {
    height: 100%;
  }

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const ScheduleBtns = styled.div`
  display: flex;
  gap: 2.4rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    gap: 1.6rem;
    margin-top: 1.6rem;
  `}
`

export const ScheduleExamButton = styled.a`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-decoration: none;
  font-weight: 700;
  color: var(--blueBtn);
  background: ${props => props.theme.colors.brand.primary[10]};
  width: fit-content;
  margin: ${props => props.noMargin ? 0 : '2.4rem 0 0'};
  padding: 1.2rem 1.6rem;
  border-radius: 4px;
  opacity: 0.9;
  line-height: 150%;

  &:hover {
    opacity: 1;
  }

  ${media.lessThan('medium')`
    width: 100%;
    margin: 0;
  `}
`

export const ScheduleDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
`

export const ExamSchedule = styled(Title)`
  font-size: 20px;
  font-weight: 700;
  color: ${props => props.theme.colors.brand.primary[10]};
  letter-spacing: -1px;
  line-height: 120%;
  max-width: none;
  margin-bottom: .8rem;
  
  ${media.lessThan('medium')`
    font-size: 20px;
    letter-spacing: inherit;
  `}
`

export const ExamScheduleDescription = styled(OtherNames)`
  line-height: 133%;
`

export const LinePink = styled(PinkLine)`
  z-index: 3;
`

export const CopyButton = styled(Button)``

export const ViewAllButton = styled(Button)`
  background: ${props => props.theme.colors.brand.primary[10]} !important;
`
export const ViewAllButtonContainer = styled(CopyPasteButton)`
`

const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  to {
    transform: translateY(0);
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: ${fadeIn} 0.3s forwards;
  z-index: 4;
`

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 660px;
  max-width: 90%;
  position: relative;
  transform: translateY(-50px);
  animation: ${slideIn} 0.3s forwards;
  z-index: 5;

  ${media.lessThan('medium')`
    max-width: 82%;
  `}
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
`

export const ModalBody = styled.div`
  max-height: 400px;
  overflow-y: auto;
`

export const TitleModalGenes = styled.h2`
  font-size: 20px;
  font-weight: 700;
  color: ${props => props.theme.colors.brand.primary[10]};
  letter-spacing: -1px;
  line-height: 120%;
  max-width: none;
  margin-bottom: .8rem;
  
  ${media.lessThan('medium')`
    font-size: 20px;
    letter-spacing: inherit;
  `}
`

export const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  height: 2.4rem;
  position: absolute;
  top: 37px;
  right: 34px;
  cursor: pointer;

  ${media.lessThan('medium')`
      top: auto;
      bottom: 2.8rem;
      right: calc((100vw - 5.5rem) / 2);
  `}
`

export const ButtonClose = styled(CircleButton)`
  width: 2rem;
  height: 2rem;
  background-color: var(--pink);

  svg {
      font-size: 1.6rem;
  }

  ${media.lessThan('medium')`
      display: none;
  `}
`

export const ButtonText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 133%;
  margin-left: .6rem;
  color: ${alvTokens.ColorPaletteGray30};
  
  ${media.lessThan('medium')`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 6.3rem;
      height: 3.2rem;
      margin: 0;
      font-weight: 700;
      font-size: 14px;
      line-height: 115%;
  `}
`

export const ModalBtns = styled.div`
  display: flex;
  gap: 24px;
`